import {
  faPersonDigging,
  faPersonWalkingDashedLineArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTssVillageById } from "../lib";
import { BaseOverview } from "./base/BaseOverview";
import { CropsOverview } from "./crops/CropsOverview";
import { LabourExchangeIcon } from "./employment/LabourExchangeIcon";
import { ManageEmploymentButton } from "./employment/ManageEmploymentButton";
import { ExpensesOverview } from "./expenses/ExpensesOverview";
import { EXPENSES_TYPE } from "./expenses/partials/shared";
import { FishingWildFoodOverview } from "./fishingWildFood/FishingWildFoodOverview";
import { FoodAidOverview } from "./foodAid/FoodAidOverview";
import { InterviewOverviewTotalSum } from "./InterviewOverviewTotalSum";
import { LivestockOverview } from "./livestock/LivestockOverview";
import { RemittancesCreditOverview } from "./remittancesCredit/RemittancesCreditOverview";
import { SelfEmploymentOverview } from "./selfEmployment/SelfEmploymentOverview";

type InterviewOverviewProps = {
  id: string;
  projectId: string;
};

export default function InterviewOverview({
  id,
  projectId,
}: InterviewOverviewProps) {
  let village = useTssVillageById(id);
  const total = village?.total;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const hasHhSize = village?.baseData?.people_in_household;
  return !hasHhSize ? (
    <Grid xs sm={2}>
      <BaseOverview interviewId={id} />
    </Grid>
  ) : (
    <>
      <Grid xs sm={2}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <BaseOverview interviewId={id} />
          <InterviewOverviewTotalSum
            id={id}
            showDiffCalories
            showDiffIncomeExpenses
          />
        </Stack>
      </Grid>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        divider={
          isMobile ? undefined : <Divider orientation="vertical" flexItem />
        }
        spacing={2}
      >
        <Box flexGrow={1}>
          <LivestockOverview interviewId={id} />
        </Box>
        <Box flexGrow={1}>
          <CropsOverview interviewId={id} />
        </Box>
      </Stack>

      <Grid container spacing={2} mt={4}>
        <Grid xs={12} sm={6} md={4}>
          <ExpensesOverview
            interviewId={id}
            expensesType={EXPENSES_TYPE.FOOD}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <ExpensesOverview
            interviewId={id}
            expensesType={EXPENSES_TYPE.NON_FOOD}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <ManageEmploymentButton
            name={"labourJobs"}
            icon={<FontAwesomeIcon icon={faPersonDigging} />}
            interviewId={id}
            calories={total?.calories_labour_jobs}
            income={total?.cash_income_labour_jobs || 0}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <SelfEmploymentOverview interviewId={id} />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <FishingWildFoodOverview interviewId={id} />
        </Grid>

        <Grid xs={12} sm={6} md={4}>
          <ManageEmploymentButton
            name={"labourExchange"}
            icon={<LabourExchangeIcon />}
            interviewId={id}
            calories={total?.calories_labour_exchange}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <ManageEmploymentButton
            name={"migration"}
            interviewId={id}
            icon={
              <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
            }
            calories={total?.calories_migration}
            income={total?.cash_income_migration}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <RemittancesCreditOverview interviewId={id} />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <FoodAidOverview interviewId={id} />
        </Grid>
      </Grid>
    </>
  );
}
