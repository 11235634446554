import { Box, useMediaQuery, useTheme } from "@mui/material";
import type { PropsWithChildren } from "react";

export function FlexLineBreak({
  hideFrom = "sm",
}: PropsWithChildren<{
  hideFrom?: "sm" | "md" | "lg";
}>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(hideFrom));
  if (!isMobile) {
    return null;
  }
  return <Box flexBasis={"100%"} height={0} />;
}
