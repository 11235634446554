import {getProjectMonths, Livestock_Type_Enum, Month_Enum, TssVillageData, useTssState} from "../../lib";
import {calculateMonthlyBreakdownLivestock} from "./calculateMonthlyBreakdownLivestock";
import {calculateMonthlyBreakdownPurchases} from "./calculateMonthlyBreakdownPurchases";
import {calculateMonthlyBreakdownNumericBreakdowns} from "./calculateMonthlyBreakdownNumericBreakdowns";
import {calculateMonthlyBreakdownCrops} from "./calculateMonthlyBreakdownCrops";
import {MonthlyBreakdownData} from "../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import cleanDeep from 'clean-deep';
import mergeWith from "lodash/mergeWith";
import {calculateBusinessEfficiency} from "./calculateBusinessEfficiency";
import {sumObjectFields} from "../../lib/helpers/arrayFieldSum";
import {MONTHS} from "../../hooks/monthHelper";

export function calculateMonthlyBreakdown(data: TssVillageData) {

    const monthsOfPeriod = getProjectMonths(data.id) || MONTHS
    // @ts-ignore
    const monthlyBreakdown: {
        [K in Month_Enum]: MonthlyBreakdownData
    } = {}
    monthsOfPeriod.forEach(m => {
        monthlyBreakdown[m] = {
            total_kcal: 0,
            total_expenditure: 0,
            total_income: 0,
            income: {
                // @ts-ignore
                livestock: {},
                livestock_totals: {
                    animalSales: 0,
                    metaLivestockProducts: 0
                },
                labourAndJob: 0,
                selfEmployment: 0,
                wildFood: 0,
                migration: 0,
                remittanceAndCredit: 0,
                foodAid: 0,
                crops: 0,
                metaOtherIncome: 0
            },
            expenditure: {
                // @ts-ignore
                livestock: {},
                livestock_totals: {
                    animalPurchase: 0,
                    animalInputPurchase: 0,
                    metaTotal: 0
                },
                purchasedFood: {
                    staples: 0,
                    nonStaples: 0
                },
                purchasedNonFood: {
                    clothing: 0,
                    health: 0,
                    education: 0,
                    essentialHouseholdItems: 0,
                    transport: 0,
                    other: 0,
                    otherProduction: 0
                },
                wildFood: 0,
                remittanceAndCredit: 0,
                crops: 0,
                metaInputsAndBusinessCost: 0,
                metaOtherExpenses: 0,
                metaHealthEducation: 0
            },
            kcal: {
                // @ts-ignore
                livestock: {},
                livestock_totals: {
                    milk: 0,
                    exchanged: 0,
                    meatEggConsumed: 0
                },
                purchasedFood: {
                    staples: 0,
                    nonStaples: 0
                },
                labourAndJob: 0,
                wildFood: 0,
                labourExchange: 0,
                migration: 0,
                foodAid: 0,
                crops: {
                    dryHarvested: 0,
                    green: 0,
                    root: 0,
                    leftoverCerealReserves: 0
                },
                metaCrops: 0,
                metaBarteredLivestock: 0,
                metaLivestockProduct: 0,
                metaMealsFoodPayment: 0
            }
        }
    })
    let yearlyBreakdown = {}
    calculateMonthlyBreakdownLivestock(data.livestock, monthlyBreakdown, monthsOfPeriod)
    calculateMonthlyBreakdownPurchases({
        food: data.purchasesFood,
        nonFood: data.purchasesNonFood
    }, monthlyBreakdown, monthsOfPeriod)
    calculateMonthlyBreakdownNumericBreakdowns(data, monthlyBreakdown, monthsOfPeriod)
    calculateMonthlyBreakdownCrops(data.crops, monthlyBreakdown, monthsOfPeriod)
    Object.keys(monthlyBreakdown).forEach((month) => {
        const m = month as Month_Enum
        const currentData = monthlyBreakdown[m]
        // total income
        monthlyBreakdown[m].total_income = sumObjectFields(currentData.income, ['foodAid', 'wildFood', 'migration', 'labourAndJob', 'remittanceAndCredit', 'selfEmployment', 'crops'])
        Object.keys(currentData.income.livestock).forEach(livestock => {
            const lData = currentData.income.livestock[livestock as Livestock_Type_Enum];
            monthlyBreakdown[m].total_income += sumObjectFields(lData, ['animalProductSales', 'animalSales', 'meatSales', 'milkSales'])
            monthlyBreakdown[m].income.livestock_totals.metaLivestockProducts += sumObjectFields(lData, ['milkSales', 'animalProductSales', 'meatSales'])
            monthlyBreakdown[m].income.livestock_totals.animalSales += sumObjectFields(lData, ['animalSales'])
        })
        monthlyBreakdown[m].income.metaOtherIncome += sumObjectFields(currentData.income, ['remittanceAndCredit', 'foodAid'])

        // total kcal
        monthlyBreakdown[m].total_kcal += sumObjectFields(currentData.kcal, ['foodAid', 'wildFood', 'labourAndJob', 'labourExchange', 'migration'])
        monthlyBreakdown[m].total_kcal += sumObjectFields(currentData.kcal.crops, ['leftoverCerealReserves', 'green', 'dryHarvested', 'root'])
        monthlyBreakdown[m].total_kcal += sumObjectFields(currentData.kcal.purchasedFood, ['nonStaples', 'staples'])
        Object.keys(currentData.kcal.livestock).forEach(livestock => {
            const lData = currentData.kcal.livestock[livestock as Livestock_Type_Enum];
            monthlyBreakdown[m].total_kcal += sumObjectFields(lData, ['milk', 'meatEggConsumed', 'exchanged'])
            monthlyBreakdown[m].kcal.livestock_totals.meatEggConsumed += lData.meatEggConsumed
            monthlyBreakdown[m].kcal.livestock_totals.milk += lData.milk
            monthlyBreakdown[m].kcal.livestock_totals.exchanged += lData.exchanged
            monthlyBreakdown[m].kcal.metaBarteredLivestock += lData.exchanged
            monthlyBreakdown[m].kcal.metaLivestockProduct += sumObjectFields(lData, ['milk', 'meatEggConsumed'])
        })
        monthlyBreakdown[m].kcal.metaCrops += sumObjectFields(currentData.kcal.crops, ['green', 'dryHarvested', 'root'])
        monthlyBreakdown[m].kcal.metaMealsFoodPayment += sumObjectFields(currentData.kcal, ['migration', 'labourExchange', 'labourAndJob'])

        // total expenditure
        monthlyBreakdown[m].total_expenditure += sumObjectFields(currentData.expenditure, ['crops', 'remittanceAndCredit', 'wildFood'])
        monthlyBreakdown[m].total_expenditure += sumObjectFields(currentData.expenditure.purchasedFood, ['staples', 'nonStaples'])
        monthlyBreakdown[m].total_expenditure += sumObjectFields(currentData.expenditure.purchasedNonFood, ['essentialHouseholdItems', 'education', 'health', 'other', 'clothing', 'transport', 'otherProduction'])
        Object.keys(currentData.expenditure.livestock).forEach(livestock => {
            const lData = currentData.expenditure.livestock[livestock as Livestock_Type_Enum];
            monthlyBreakdown[m].total_expenditure += sumObjectFields(lData, ['animalPurchase', 'animalInputPurchase'])
            monthlyBreakdown[m].expenditure.livestock_totals.metaTotal += sumObjectFields(lData, ['animalPurchase', 'animalInputPurchase'])
            monthlyBreakdown[m].expenditure.livestock_totals.animalPurchase += lData.animalPurchase
            monthlyBreakdown[m].expenditure.livestock_totals.animalInputPurchase += lData.animalInputPurchase
        })
        monthlyBreakdown[m].expenditure.metaHealthEducation += sumObjectFields(currentData.expenditure.purchasedNonFood, ['health', 'education'])
        monthlyBreakdown[m].expenditure.metaInputsAndBusinessCost += sumObjectFields(currentData.expenditure, ['wildFood', 'crops'])
        monthlyBreakdown[m].expenditure.metaInputsAndBusinessCost += sumObjectFields(currentData.expenditure.livestock_totals, ['animalInputPurchase', 'animalPurchase'])
        monthlyBreakdown[m].expenditure.metaInputsAndBusinessCost += sumObjectFields(currentData.expenditure.purchasedNonFood, ['otherProduction'])
        monthlyBreakdown[m].expenditure.metaOtherExpenses += sumObjectFields(currentData.expenditure.purchasedNonFood, ['transport', 'other', 'clothing'])
        monthlyBreakdown[m].expenditure.metaOtherExpenses += currentData.expenditure.remittanceAndCredit

        //summarize for yearly data
        yearlyBreakdown = mergeWith({...yearlyBreakdown}, {...monthlyBreakdown[m]}, (objValue: number | string | object, srcValue: number | string | object) => {
            // console.log(objValue, srcValue, key)
            if (typeof srcValue === 'number' && typeof objValue === 'number') {
                return srcValue + objValue
            }
            return undefined
        })
    })

    calculateBusinessEfficiency(data)

    useTssState.getState().setVillageData(data.id, {
        monthBasedCalculation: cleanDeep(monthlyBreakdown, {
            cleanValues: [0 as any],
            NaNValues: true
        }),
        totalOnMonthlyCalculation: cleanDeep(yearlyBreakdown, {
            cleanValues: [0 as any],
            NaNValues: true
        })
    } as TssVillageData)
}