import {PropsWithChildren} from "react";
import {useTssState} from "../lib";
import {useShallow} from "zustand/react/shallow";
import {InterviewContext, InterviewProviderProps} from "./InterviewProvider";

export function InterviewProvider({id, projectId, children}: PropsWithChildren<InterviewProviderProps>) {
    const [projects] = useTssState(useShallow(state => [state.projects]))
    const countryId = projects.find(i => i.id === projectId)?.project_interviews?.find(i => i.id === id)?.location?.country_region?.country?.id ?? null

    return <InterviewContext.Provider value={{id, countryId, projectId}}>{children}</InterviewContext.Provider>
}