import { TextFieldElement, TextFieldElementProps } from "react-hook-form-mui";
import {
  FormFieldWithLabel,
  FormFieldWithLabelProps,
} from "./FormFieldWithLabel";
import { TextFieldNumberFormat } from "./TextFieldNumberFormat";

type FormFieldWithNumberInputProps = TextFieldElementProps & {
  labelSize?: FormFieldWithLabelProps["size"];
  isNumberFormat?: boolean;
  flex?: number;
  labelOneLine?: boolean;
};

export function TextFieldLabelInput({
  label,
  name,
  type,
  labelSize,
  isNumberFormat,
  flex,
  labelOneLine,
  ...rest
}: FormFieldWithNumberInputProps) {
  return (
    <FormFieldWithLabel
      labelOneLine={labelOneLine}
      label={label as string}
      size={flex ? undefined : labelSize || "md"}
      flex={flex}
    >
      {isNumberFormat ? (
        <TextFieldNumberFormat
          name={name}
          size={"small"}
          {...rest}
          fullWidth={flex ? true : rest.fullWidth}
        />
      ) : (
        <TextFieldElement
          name={name}
          type={type || "text"}
          {...(type !== "text" && {
            inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
          })}
          size={"small"}
          {...rest}
          fullWidth={flex ? true : rest.fullWidth}
        />
      )}
    </FormFieldWithLabel>
  );
}
