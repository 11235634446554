import {TssVillageData} from "../../../lib";
import {sumArrayObjectFields} from "../../../lib/helpers/arrayFieldSum";

export function getCropsSumFields(data: TssVillageData['crops']) {
    const values = {
        calories: 0,
        income: 0,
        expenses: 0
    }
    Object.keys(data).forEach(interviewId => {
        const interview = data[interviewId] ?? {}
        const income = sumArrayObjectFields(interview.production ?? [], ['total_cash_income'])
        const kcal = sumArrayObjectFields(interview.production ?? [], ['total_kcal'])
        const sumExpenditures = sumArrayObjectFields(interview.expenditures ?? [], ['total_expenses'])
        values.income = values.income + (Number(income?.total_cash_income ?? 0))
        values.expenses = values.expenses + (Number(sumExpenditures?.total_expenses ?? 0))
        values.calories = values.calories + ((interview.green_crops?.total_kcal || 0) + (interview.root_crops?.total_kcal || 0) + (kcal.total_kcal || 0))
    })
    return values
}