import {Card, CardContent, Stack, Typography, Unstable_Grid2 as Grid,} from "@mui/material";
import {TextFieldElement} from "react-hook-form-mui";
import {useTranslation} from "react-i18next";
import {FormStack} from "../../../common/form/FormStack";
import {KeyValueForm} from "../../../common/form/KeyValueForm";
import {MeasureSelector} from "../../../common/form/MeasureSelector";
import {MonthMultiSelectElement} from "../../../common/form/MonthMultiSelectElement";
import {TermsSelector} from "../../../common/form/TermsSelector";
import {TextFieldNumberFormat} from "../../../common/form/TextFieldNumberFormat";
import {LmTipTapRichtextRhf} from "../../../common/form/richText/TipTapRhf";
import {Measure_Unit_Type_Enum} from "../../../lib";
import {LeftoverCerealSum} from "./LeftoverCerealSum";
import {DatePickerFeg} from "../../../common/form/date/DatePickerFeg";

export function InterviewBaseForm() {
    const {t} = useTranslation();
    return (
        <Stack>
            <Grid container spacing={3} mt={1}>
                <Grid xs={12} md={6}>
                    <Card variant={"outlined"}>
                        <CardContent>
                            <Stack spacing={2} direction={"column"}>
                                <Stack gap={1} direction={{xs: "column", sm: "row"}}>
                                    <TextFieldElement
                                        name={"people_in_household"}
                                        type={"number"}
                                        label={t("interview.form.peopleHousehold")}
                                        fullWidth
                                    />
                                    <TextFieldElement
                                        name={"children_primary_school"}
                                        fullWidth
                                        type={"number"}
                                        label={t("interview.form.childrenPrimarySchool")}
                                    />
                                    <TextFieldElement
                                        name={"children_secondary_school"}
                                        fullWidth
                                        type={"number"}
                                        label={t("interview.form.childrenSecondarySchool")}
                                    />
                                </Stack>
                                <Stack gap={1} direction={"row"}>
                                    <TextFieldNumberFormat
                                        name={"land_owned"}
                                        type={"number"}
                                        label={t("interview.form.landOwned")}
                                    />
                                    <MeasureSelector
                                        name={"land_unit_conversion"}
                                        measureType={Measure_Unit_Type_Enum.LandSize}
                                        label={t("interview.form.landUnit")}
                                        fullWidth
                                    />
                                </Stack>
                                <Stack gap={1} direction={{xs: "column", sm: "row"}}>
                                    <TextFieldNumberFormat
                                        name={"land_cultivated_food"}
                                        type={"number"}
                                        fullWidth
                                        label={t("interview.form.landCultivatedFood")}
                                    />
                                    <TextFieldNumberFormat
                                        name={"land_cultivated_cash"}
                                        type={"number"}
                                        fullWidth
                                        label={t("interview.form.landCultivatedCash")}
                                    />
                                    <TextFieldNumberFormat
                                        name={"land_outsourced"}
                                        type={"number"}
                                        fullWidth
                                        label={t("interview.form.landRentedOut")}
                                        helperText={t("interview.form.landRentedOutHelper")}
                                    />
                                </Stack>
                            </Stack>
                            <KeyValueForm
                                label={t("interview.form.otherProductiveAssets")}
                                name={"other_productive_assets"}
                                filterCategories={["99b13683-f538-4595-aefa-9f249a2b7637"]}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card variant={"outlined"}>
                        <CardContent>
                            <Stack
                                gap={1}
                                marginBottom={2}
                                direction={{xs: "column", sm: "row"}}
                            >
                                <TextFieldElement
                                    fullWidth
                                    name={"interviewees_female"}
                                    label={t("interview.form.intervieweesFemale")}
                                />
                                <TextFieldElement
                                    fullWidth
                                    name={"interviewees_male"}
                                    label={t("interview.form.intervieweesMale")}
                                />
                                <DatePickerFeg
                                    inputProps={{fullWidth: true}}
                                    name={"date"}
                                    label={t("interviewDate")}
                                />
                            </Stack>

                            <LmTipTapRichtextRhf name={"comments"}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Typography paddingTop={2} marginY={3}>
                {t("leftoverCereal")}
            </Typography>
            <FormStack direction={{xs: "column", sm: "row"}}>
                <TermsSelector
                    autocompleteProps={{size: "small"}}
                    filterCategories={["f50792d4-25cd-4bbd-b7a4-19f096957299"]}
                    name={"leftover_cereal_term"}
                    label={t("cerealType")}
                />
                <TextFieldElement
                    name={"leftover_cereal_amount"}
                    type={"number"}
                    size={"small"}
                    label={t("amountKg")}
                />
                <MonthMultiSelectElement
                    name={"leftover_cereal_months"}
                    size={"small"}
                    label={t("monthsOfConsumption")}
                    minWidth={200}
                />
                <LeftoverCerealSum/>
            </FormStack>
        </Stack>
    );
}
