import {AutocompleteElement, AutocompleteElementProps} from 'react-hook-form-mui'
import {useTranslation} from 'react-i18next'
import {FieldValues} from 'react-hook-form'
import {useGetTerms} from "../../lib/state/allVariablesState";

type TermsSelectorProps<T extends FieldValues> = Omit<AutocompleteElementProps<T, any, any, any>, 'options'> & {
    filterCategories?: string[]
}

export function TermsSelector<TFieldValues extends FieldValues>({
                                                                    name,
                                                                    multiple,
                                                                    filterCategories,
                                                                    ...rest
                                                                }: TermsSelectorProps<TFieldValues>) {
    const {i18n} = useTranslation()
    const locale = i18n.language || 'en'
    const getTerms = useGetTerms()
    const opts = getTerms(filterCategories, locale);


    return (
        <AutocompleteElement
            {...rest}
            name={name}
            textFieldProps={{
                sx: {
                    minWidth: '250px'
                }
            }}
            options={opts}/>
    )
}

