import {ReactNode, useState} from "react";
import {MuiFileInput, MuiFileInputProps} from "mui-file-input";
import {AttachFile} from "@mui/icons-material";
import {PopperContainer} from "../../popper/PopperContainer";
import {Badge, ButtonProps, List, Stack} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useFileUpload, useMultipleFilesUpload} from "@nhost/react";
import {FileUploadMultiItem} from "./FileUploadMultiItem";
import {FileUploadItem} from "./FileUploadItem";
import {FileListItem} from "./fileUploadTypes";


type FileUploadMenuProps = {
    fileInputProps?: Pick<MuiFileInputProps, 'multiple'>
    afterUpload: (ids: string | string[]) => Promise<void>
    isLoading: boolean
    fileList?: FileListItem[]
    refetchFileQuery: () => Promise<any>
    triggerProps?: ButtonProps
    CustomSelector?: ReactNode
}


export function FileUploadMenu({
                                   fileInputProps,
                                   afterUpload,
                                   isLoading,
                                   fileList,
                                   triggerProps,
                                   CustomSelector,
                                   refetchFileQuery
                               }: FileUploadMenuProps) {
    const [value, setValue] = useState<File[] | File | undefined | null>()
    const {upload: uploadSingle, isUploading: isUploadingSingle, destroy} = useFileUpload()

    const {upload, isUploading, files, add, clear} = useMultipleFilesUpload()

    const fileCount = fileList?.length ?? 0
    const triggerLabel = triggerProps?.children ?? 'Files'
    return (

        <PopperContainer variant={'popover'} trigger={{
            ...triggerProps,
            children: fileCount ? (
                <Badge badgeContent={fileCount} color="warning">
                    {triggerLabel}
                </Badge>
            ) : triggerLabel,
            startIcon: <AttachFile/>
        }}>
            <Stack spacing={2} padding={2}>
                {fileCount > 0 && (
                    <List dense>
                        {fileList?.map(file => (
                            <FileUploadItem file={file} key={file.file.id} refetchFileQuery={refetchFileQuery}/>
                        ))}
                    </List>
                )}

                {fileInputProps?.multiple ? (
                    <MuiFileInput
                        {...(fileInputProps)}
                        multiple
                        contentEditable={'false'}
                        label={'Select Files'}
                        size={'small'}
                        value={value as File[]}
                        onChange={(v) => {
                            add({files: v as any})
                        }}/>
                ) : (
                    <MuiFileInput
                        {...fileInputProps}
                        multiple={false}
                        contentEditable={'false'}
                        label={'Select File'}
                        size={'small'}
                        value={value as File}
                        onChange={(v) => {
                            setValue(v)
                        }}/>
                )}

                {CustomSelector}

                {!!files?.length && (
                    <Stack>
                        <List>
                            {files.map(f => (
                                <FileUploadMultiItem itemRef={f} key={f.id}/>
                            ))}
                        </List>
                        <LoadingButton
                            loading={isUploading || isUploadingSingle || isLoading}
                            onClick={async () => {
                                const res = await upload()
                                if (res.files?.length) {
                                    const fileIds = res.files
                                        .map(({state}) => state.context.id)
                                        .filter(i => i) as string[]
                                    await afterUpload(fileIds)
                                    await refetchFileQuery()
                                    clear()
                                }
                            }}>
                            Upload
                        </LoadingButton>
                    </Stack>
                )}
                {value && (
                    <Stack spacing={1}>
                        <LoadingButton
                            loading={isUploading || isUploadingSingle || isLoading}
                            onClick={async () => {
                                const res = await uploadSingle({file: value as File})
                                if (res.id) {
                                    await afterUpload(res.id)
                                    await refetchFileQuery()
                                    destroy()
                                }
                            }}>
                            Upload
                        </LoadingButton>
                    </Stack>
                )}
            </Stack>

        </PopperContainer>


    )
}