import { Chip, Stack, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function TotalAnimalsLabel() {
  const { t } = useTranslation();
  const first = useWatch({
    name: "animals_other",
  });
  const second = useWatch({
    name: "breeding_female",
  });
  const count = Number(first || 0) + Number(second || 0);
  if (!count) {
    return null;
  }
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <Typography>{t("livestock.totalAnimals", { ns: "tss" })}</Typography>

      <Chip
        label={count}
        color={"primary"}
        size={"medium"}
        sx={{ fontSize: 20 }}
      />
    </Stack>
  );
}
