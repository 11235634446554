import {useTssNotesQuery} from "../../lib";
import {useEffect, useMemo} from "react";
import {TssNotesElement} from "./TssNotesDrawer";
import {useTssNotesSetCurrentInterviewId, useTssNotesSyncData} from "./TssNotesProvider";

export default function TssNotesContainer({interviewId}: {
    interviewId: string
}) {
    const setInterviewId = useTssNotesSetCurrentInterviewId()
    const {data, isLoading: isQueryLoading} = useTssNotesQuery({
        interviewId
    }, {
        enabled: !!interviewId,
        gcTime: 0,
        staleTime: 0
    })
    const syncData = useTssNotesSyncData()
    const onlineList = useMemo(
        () => data?.project_interview_note ?? [],
        [data?.project_interview_note]
    )

    useEffect(() => {
        syncData(onlineList)
    }, [onlineList, syncData]);

    useEffect(() => {
        setInterviewId(interviewId)
    }, [interviewId, setInterviewId]);


    return <TssNotesElement/>
}