import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";

import { Info } from "@mui/icons-material";
import { PageRoot } from "../common/layout/PageRoot";
import { PopperContainer } from "../common/popper/PopperContainer";
import { TssVillageData, useOnlineInterviewData, useSetVillageData, useTssProjects, useTssVillageById } from "../lib";
import { TssLayout } from "../theme/layouts/tss";
import HeaderInterviewSyncDialog from "./admin/HeaderInterviewSyncDialog";
import InterviewOverview from "./InterviewOverview";
import { useInterviewContext } from "./InterviewProvider";
import { InterviewStatusIcon } from "./InterviewStatusIcon";
import { HeaderInterviewMonthlyBreakdownBtn } from "./monthlyCalculations/HeaderInterviewMonthlyBreakdownBtn";
import { TotalSummaryTableBtn } from "./monthlyCalculations/TotalSummaryTableBtn";
import TssNotesContainer from "./notes/TssNotesContainer";

export default function InterviewOverviewLayout() {
    const {projectId, id} = useInterviewContext()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <PageRoot title={'Interview'}>
            <TssLayout
                projectId={projectId}
                title={<InterviewTitle/>}
                headerEndSection={
                    isMobile ?(
                       <PopperContainer
                       variant="popover" 
                       triggerIcon={{
                        children: <Info color="inherit"/>
                       }}>
                        <TssNotesContainer interviewId={id}/>
                        <TotalSummaryTableBtn interviewId={id}/>
                        <HeaderInterviewMonthlyBreakdownBtn interviewId={id}/>
                        <HeaderInterviewSyncDialog/>
                       </PopperContainer> 
                    ) :
                    <Stack direction={'row'} spacing={2}>
                        <TssNotesContainer interviewId={id}/>
                        <TotalSummaryTableBtn interviewId={id}/>
                        <HeaderInterviewMonthlyBreakdownBtn interviewId={id}/>
                        <HeaderInterviewSyncDialog/>
                    </Stack>
                }
            >
                <InterviewOverview id={id} projectId={projectId}/>
            </TssLayout>
        </PageRoot>
    )
}

function InterviewTitle() {
    const {projectId, id: interviewId} = useInterviewContext()
    const village = useTssVillageById(interviewId)
    const set = useSetVillageData()
    const projects = useTssProjects()
    const onlineData = useOnlineInterviewData()
    const isTssSetup = village?.id
    const tssDataPartial = projects.find(p => p.id === projectId)
        ?.project_interviews.find(i => i.id === interviewId)

    useEffect(
        () => {
            if (!isTssSetup && tssDataPartial) {
                set(interviewId, tssDataPartial as TssVillageData, true)
            }
        }, [interviewId, isTssSetup, set, tssDataPartial]
    )
    const interviewStatus = onlineData?.interviewStatus
    const currentTitle = onlineData.interview?.location?.name + '-' + onlineData.interview?.wealth_group?.en;
    return (
        <Stack direction={'row'} spacing={2} alignItems={'center'} maxWidth={{xs: "50%", sm:"100%"}}>
            <Typography
                variant={'h4'} noWrap  title={currentTitle}>{currentTitle}</Typography>
            <InterviewStatusIcon status={interviewStatus}
                                 is_done={onlineData?.is_done}
                                 hasUploadId={onlineData?.id}/>
        </Stack>
    )
}