import { faCow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem } from "@mui/material";
import { PopupState } from "material-ui-popup-state/hooks";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PopperContainer } from "../../common/popper/PopperContainer";
import { Livestock_Type_Enum } from "../../lib";
import LivestockFormStepperDialog from "./LivestockFormStepperDialog";

const LIVESTOCK_ORDERED: Livestock_Type_Enum[] = [
  Livestock_Type_Enum.Cattle,
  Livestock_Type_Enum.Goat,
  Livestock_Type_Enum.Sheep,
  Livestock_Type_Enum.Camel,
  Livestock_Type_Enum.Chicken,
  Livestock_Type_Enum.Pig,
  Livestock_Type_Enum.Oxen,
  Livestock_Type_Enum.OtherPoultry,
  Livestock_Type_Enum.Donkey,
  Livestock_Type_Enum.Horse,
  Livestock_Type_Enum.Yak,
  Livestock_Type_Enum.Buffalo,
];

type AddLivestockButtonProps = {
  villageId: string;
  used?: Livestock_Type_Enum[];
};

export default function AddLivestockButton({
  villageId,
  used,
}: AddLivestockButtonProps) {
  const popperRef = useRef<PopupState | null>(null);
  const [selectedLivestock, setSelectedLivestock] = useState<
    Livestock_Type_Enum | undefined
  >();
  const { t } = useTranslation();
  return (
    <>
      <PopperContainer
        ref={popperRef}
        variant={"popover"}
        popupId={"add-livestock-button"}
        trigger={{
          variant: "outlined",
          startIcon: <FontAwesomeIcon icon={faCow} />,
          endIcon: <ArrowDropDownIcon />,
          children: t("addLivestock"),
        }}
        menuColumnCount={3}
      >
        {LIVESTOCK_ORDERED.map((key) => {
          // @ts-ignore
          const livestockEnum = key;
          return (
            <MenuItem
              key={key}
              disabled={used?.some((i) => i === key)}
              onClick={() => {
                // @ts-ignore
                setSelectedLivestock(livestockEnum as Livestock_Type_Enum);
                popperRef.current?.close();
              }}
            >
              {t(`livestock.type.${livestockEnum?.toLowerCase()}`)}
            </MenuItem>
          );
        })}
      </PopperContainer>
      {selectedLivestock && (
        <LivestockFormStepperDialog
          villageId={villageId}
          selectedLivestock={selectedLivestock}
          onClose={() => setSelectedLivestock(undefined)}
        />
      )}
    </>
  );
}
