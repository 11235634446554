import CaloriesIncomeExpense from "../../shared/CaloriesIncomeExpense";
import {useWatch} from "react-hook-form";
import {SelfEmploymentData} from "../../../lib";
import {arrayFieldSum} from "../../../lib/helpers/arrayFieldSum";

export function SelfEmploymentDialogSum() {
    const values: SelfEmploymentData[] = useWatch({name: 'self_employment'})
    const income = arrayFieldSum(values || [], 'total_cash_income')
    return (
        <CaloriesIncomeExpense income={income}/>
    )
}