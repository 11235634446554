import {
  Alert,
  Card,
  CardContent,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import { SwitchElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import TotalVolumeDay from "./TotalVolumeDay";

import { useWatch } from "react-hook-form";
import { FlexLineBreak } from "../../../../common/display/FlexLineBreak";
import { FormFieldWithLabel } from "../../../../common/form/FormFieldWithLabel";
import { FormStack } from "../../../../common/form/FormStack";
import { MeasureSelector } from "../../../../common/form/MeasureSelector";
import { MonthSelectElement } from "../../../../common/form/MonthSelectElement";
import { TextFieldLabelInput } from "../../../../common/form/TextFieldLabelInput";
import { Livestock_Type_Enum, Measure_Unit_Type_Enum } from "../../../../lib";
import { LivestockFormData } from "../../../../lib/state/tssTypes/tssLivestockTypes";

type MilkingFormProps = {
  namePrefix: keyof Pick<
    LivestockFormData,
    | "milkProductionOffPeakSecondary"
    | "milkProductionOffPeak"
    | "milkProductionPeakSecondary"
    | "milkProductionPeak"
  >;
  livestockType: Livestock_Type_Enum;
};

function SkimMilkAlert({ namePrefix }: { namePrefix: string }) {
  const { t } = useTranslation();
  const isEnabled = useWatch({ name: `${namePrefix}.consumes_skim` });
  return (
    <Collapse in={isEnabled}>
      <Alert
        sx={{
          maxWidth: "250px",
        }}
        severity={"warning"}
      >
        {t("livestock.skimWarningMessage")}
      </Alert>
    </Collapse>
  );
}

export default function MilkingForm({
  namePrefix,
  livestockType,
}: MilkingFormProps) {
  const { t } = useTranslation();

  return (
    <Stack justifyContent={{ xs: "column", md: "row" }} alignItems={"center"}>
      <TotalVolumeDay namePrefix={namePrefix} livestockType={livestockType} />
      <Stack
        flexDirection={["column", "column", "row"]}
        gap={"16px"}
        justifyContent={"center"}
        marginTop={"16px"}
        width={"100%"}
      >
        <Stack>
          <Typography variant={"h6"}>{t("production")}</Typography>
          <Card
            sx={{
              flex: 1,
            }}
            variant={"outlined"}
          >
            <CardContent>
              <FormStack direction={["column", "row"]} flexWrap={"wrap"}>
                <TextFieldLabelInput
                  flex={1}
                  label={t("livestock.milkedFemale", { ns: "tss" })}
                  name={`${namePrefix}.milked_female`}
                />
                <TextFieldLabelInput
                  flex={1}
                  label={t("livestock.daysMilked", { ns: "tss" })}
                  name={`${namePrefix}.days_milked`}
                />
                <FlexLineBreak hideFrom="md" />

                <TextFieldLabelInput
                  labelOneLine
                  flex={1}
                  label={t("livestock.milkPerAnimal", { ns: "tss" })}
                  name={`${namePrefix}.milk_per_animal`}
                />
                <FormFieldWithLabel label={t("livestock.unit", { ns: "tss" })}>
                  <MeasureSelector
                    measureType={Measure_Unit_Type_Enum.Volume}
                    name={`${namePrefix}.measure_unit`}
                    size={"small"}
                  />
                </FormFieldWithLabel>
                {(namePrefix === "milkProductionPeak" ||
                  namePrefix === "milkProductionPeakSecondary") && (
                  <FormFieldWithLabel
                    label={t("livestock.milkStartMonthPeak", { ns: "tss" })}
                  >
                    <MonthSelectElement
                      name={`${namePrefix}.peak_milk_month`}
                      size={"small"}
                    />
                  </FormFieldWithLabel>
                )}
              </FormStack>
            </CardContent>
          </Card>
        </Stack>

        <Stack>
          <Typography variant={"h6"}>{t("usage")}</Typography>

          <FormStack direction={["column", "row"]} alignItems={"stretch"}>
            <Card variant={"outlined"} sx={{ flex: 1 }}>
              <CardContent>
                <FormStack gap={0}>
                  <TextFieldLabelInput
                    flex={1}
                    label={t("livestock.consumedPerDay", { ns: "tss" })}
                    name={`${namePrefix}.quantity_consumed_day`}
                  />
                  <SwitchElement
                    name={`${namePrefix}.consumes_skim`}
                    label={t("livestock.isSkim", { ns: "tss" })}
                  />
                  <SkimMilkAlert namePrefix={namePrefix} />
                </FormStack>
              </CardContent>
            </Card>
            <Card variant={"outlined"} sx={{ flex: 1 }}>
              <CardContent>
                <FormStack gap={0}>
                  <TextFieldLabelInput
                    flex={1}
                    isNumberFormat
                    labelSize={"lg"}
                    label={t("livestock.soldPerDay", { ns: "tss" })}
                    name={`${namePrefix}.sold_per_day`}
                  />
                  <TextFieldLabelInput
                    flex={1}
                    isNumberFormat
                    labelSize={"lg"}
                    label={t("livestock.pricePerLitre", { ns: "tss" })}
                    name={`${namePrefix}.price_per_litre`}
                  />
                </FormStack>
              </CardContent>
            </Card>
            <Card variant={"outlined"} sx={{ flex: 1 }}>
              <CardContent>
                <FormStack gap={0}>
                  <TextFieldLabelInput
                    flex={1}
                    isNumberFormat
                    labelSize={"lg"}
                    label={t("livestock.quantityGivenAway")}
                    name={`${namePrefix}.quantity_given_away`}
                  />
                  <TextFieldLabelInput
                    flex={1}
                    isNumberFormat
                    labelSize={"lg"}
                    label={t("livestock.exchangedCereal")}
                    name={`${namePrefix}.exchanged_cereal`}
                  />
                </FormStack>
              </CardContent>
            </Card>
          </FormStack>
        </Stack>
      </Stack>
    </Stack>
  );
}
