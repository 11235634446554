import {Alert} from "@mui/material";
import {TssLayout} from "../../../theme/layouts/tss";
import InterviewOverviewLayout from "../../../tss/InterviewOverviewLayout";
import {PageRoot} from "../../../common/layout/PageRoot";
import {useSearchParams} from "react-router-dom";
import {InterviewProvider} from "../../../tss/InterviewContainer";

export default function Interview() {
    const [searchParams] = useSearchParams()
    const id = searchParams.get('key')
    const projectId = searchParams.get('id')
    // const id = query?.key as string;
    // const projectId = query?.id as string;

    if (!(id && projectId)) {
        return (
            <PageRoot title={'Interview'}>
                <TssLayout
                    projectId={projectId as string}
                    title={'Missing Interview ID'}
                >
                    <Alert severity={'error'}>
                        There is an error providing interview and project id. Please contact the administrator.
                    </Alert>
                </TssLayout>
            </PageRoot>
        )
    }

    return (
        <InterviewProvider id={id} projectId={projectId}>
            <InterviewOverviewLayout/>
        </InterviewProvider>
    )
}