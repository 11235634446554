import CaloriesIncomeExpense from "../../shared/CaloriesIncomeExpense";
import {ExpenditureData} from "../../../lib";
import {useWatch} from "react-hook-form";
import {arrayFieldSum} from "../../../lib/helpers/arrayFieldSum";

export function ExpensesDialogSum({name}: { name: string }) {
    const values: ExpenditureData[] = useWatch({name})

    const kcal = arrayFieldSum(values || [], 'total_kcal')
    const expenses = arrayFieldSum(values || [], 'total_expenses')
    return (
        <CaloriesIncomeExpense expenses={expenses}
                               calories={kcal ? kcal.toFixed(1) : 0}/>
    )
}