import {
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Stack,
    Tooltip,
    Typography,
    Unstable_Grid2 as Grid
} from "@mui/material";
import {TssState, useTssUpdatedAt, useTssVillageById} from "../lib";
import {useDate} from "../hooks/useDate";
import {useTranslation} from "react-i18next";
import {InterviewOverviewTotalSum} from "./InterviewOverviewTotalSum";
import {InterviewStatusIcon} from "./InterviewStatusIcon";
import {InterviewListItemComments} from "./InterviewListItemComments";
import {InterviewListItemDownload} from "./InterviewListItemDownload";
import {useIsOnline} from "../provider";
import {InterviewListItemUpload} from "./InterviewListItemUpload";
import {Link} from "react-router-dom";

const IS_SB = !!process.env.STORYBOOK

type InterviewListItemProps = {
    interview: TssState['projects'][number]['project_interviews'][number]
    project: TssState['projects'][number]
}

export function InterviewListItem({interview, project}: InterviewListItemProps) {
    const {transformDate} = useDate()
    const {t} = useTranslation()
    const interviewData = useTssVillageById(interview.id)
    // const userRole = useUserRoleInInterview(interview.id)
    const uploadedAt = interview.project_interview_base?.created_at
    const asDoneDeclared = interview.project_interview_base?.is_done
    const {isOnline} = useIsOnline()
    const updatedAt = useTssUpdatedAt(interview.id)
    let interviewStatus = t('notSynced')
    if (interview.status) {
        interviewStatus = t(`interviewStatus.${interview.status}`)
    } else if (asDoneDeclared) {
        interviewStatus = t('asDoneDeclared')
    } else if (uploadedAt) {
        interviewStatus = t('interviewUploaded')
    }
    const isAlreadyDownloaded = uploadedAt === interviewData?.created_at

    // console.log({activeLinks})
    // const isAdminRole = userRole === 'teamleader' || userRole === 'supervisor';

    return (
        <Grid xs={12} sm={6} md={4} lg={3}>
            <Card variant={'outlined'}
                  sx={{
                      borderColor: updatedAt ? isOnline ? 'error.main' : 'primary.main' : undefined,
                  }}
            >
                <CardActionArea to={`/interview?id=${project.id}&key=${interview.id}`} component={Link}>
                    <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'} paddingX={2}
                           paddingTop={2} spacing={2}>
                        <Tooltip title={interviewStatus}>
                            <Typography variant={'h6'}>{interview.wealth_group?.en}</Typography>
                        </Tooltip>
                        <Box flexGrow={1}/>
                        <InterviewListItemComments
                            interviewId={interview.id}
                            onlineCount={interview.project_interview_notes_aggregate?.aggregate?.count ?? 0}/>
                        <InterviewStatusIcon status={interview.status}
                                             is_done={asDoneDeclared}
                                             hasUploadId={uploadedAt}/>
                    </Stack>

                    <Typography variant={'h4'} paddingLeft={2} paddingY={1}>
                        {interview.location?.name || ''}
                    </Typography>
                    <CardContent sx={{paddingTop: 0}}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <div>
                                <Typography>{interview.location?.country_region?.name}</Typography>
                                <Typography
                                >{transformDate(interviewData?.baseData?.date || interview.date, {dateStyle: 'long'})}</Typography>
                                <Typography>{interview.project_interview_researcher_mns?.map(r => r.user?.displayName)
                                    .join(', ')}</Typography>
                            </div>
                            {interviewData?.total && (
                                <Box>
                                    <InterviewOverviewTotalSum id={interview.id} small stackProps={{
                                        direction: 'column'
                                    }}/>
                                </Box>
                            )}
                        </Stack>
                    </CardContent>
                </CardActionArea>
                {isOnline && (uploadedAt || updatedAt) && (
                    <CardActions>
                        {!isAlreadyDownloaded && (
                            <InterviewListItemDownload interviewId={interview.id}/>
                        )}
                        <InterviewListItemUpload interviewId={interview.id} projectId={project.id}/>
                    </CardActions>
                )}

            </Card>
        </Grid>
    )
}