import Baseline from "./pages/Baseline";
import Interview from "./pages/Interview";
import Login from "../auth/Login";
import EmailVerified from "../auth/EmailVerified";
import ForgotPassword from "../auth/ForgotPassword";
import SettingsPage from "./pages/Settings";
import Admin from "./pages/Admin";
import {createBrowserRouter, Route, RouterProvider} from 'react-router-dom'
import TssLanding from "./pages/TssLanding";
import {createRoutesFromElements} from "react-router";
import ChangePassword from "../auth/ChangePassword";
import {ViteAuthGuardOffline} from "../ViteAuthGuardOffline";
import {ViteTssGlobalProvider} from "../ViteTssGlobalProvider";
import {ErrorBoundary} from "../../common/layout/errorPages/ErrorComponent";

const tssReactRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ViteTssGlobalProvider/>} errorElement={<ErrorBoundary/>}>
            <Route element={<ViteAuthGuardOffline/>}>
                <Route index element={<TssLanding/>}></Route>
                <Route path={'/baseline'} element={<Baseline/>}/>
                <Route path={'/interview'} element={<Interview/>}/>
                <Route path={'/admin'} element={<Admin/>}/>
                <Route path={'/dashboard/user/settings'} element={<SettingsPage/>}/>
            </Route>
            <Route path={'/auth'}>
                <Route path={'login'} element={<Login/>}/>
                <Route path={'email-verified'} element={<EmailVerified/>}/>
                <Route path={'forgot-password'} element={<ForgotPassword/>}/>
                <Route path={'change-password'} element={<ChangePassword/>}/>
            </Route>
        </Route>
    )
);

export const TssReactRouter = () => (
    <RouterProvider router={tssReactRouter}/>
)