import { Box, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const sizeMap = {
  sm: "90px",
  md: "110px",
  lg: "150px",
};
export type FormFieldWithLabelProps = {
  flex?: number;
  labelOneLine?: boolean;
  label: string;
  size?: "sm" | "md" | "lg";
};
export const FormFieldWithLabel: FC<
  PropsWithChildren<FormFieldWithLabelProps>
> = ({ children, label, size, flex, labelOneLine }) => {
  return (
    <Box marginY={2} maxWidth={size ? sizeMap[size] : undefined} flex={flex}>
      <Typography
        marginBottom={1}
        variant={"caption"}
        component={"p"}
        noWrap={labelOneLine}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};
