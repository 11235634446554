import {Livestock_Type_Enum, Month_Enum, TssVillageData} from "../../lib";
import {MonthlyBreakdownData} from "../../lib/state/tssTypes/monthlyBreakdownDataTypes";
import {TERM_CATEGORIES_FILTER_FIXTURES} from "../fixtures";
import {getMonthsOnDays} from "../../hooks/monthHelper";

export const checkLivestockSalesCategory = (categories: string[] | undefined) => !!categories?.includes(TERM_CATEGORIES_FILTER_FIXTURES.LIVESTOCK)


export function calculateMonthlyBreakdownLivestock(data: TssVillageData['livestock'], months: {
    [K in Month_Enum]: MonthlyBreakdownData
}, monthsOfPeriod: Month_Enum[]) {
    const referenceMonths = monthsOfPeriod.length
    if (!data) {
        return
    }


    Object.keys(data).forEach((key) => {
        const currentData = data[key as Livestock_Type_Enum]

        // meat sales
        const meatSales = (Number(currentData.meat_quantity_sold || 0) * Number(currentData.meat_price_per_kg || 1)) / referenceMonths
        // meatEggConsumed
        const meatEggConsumed = Number(currentData.total_kcal_calories_consumed_eggs) / referenceMonths
        const milkPeakExchanged = Number(currentData.total_peak_milk_exchanged_calories) / referenceMonths
        const milkOffPeakExchanged = Number(currentData.total_off_peak_milk_exchanged_calories) / referenceMonths

        monthsOfPeriod.forEach(mo => {
            months[mo].income.livestock[currentData.type] = months[mo].income.livestock[currentData.type] || {
                animalSales: 0,
                animalProductSales: 0,
                meatSales: meatSales,
                milkSales: 0
            }
            months[mo].expenditure.livestock[currentData.type] = months[mo].expenditure.livestock[currentData.type] || {
                animalPurchase: 0,
                animalInputPurchase: 0
            }
            months[mo].kcal.livestock[currentData.type] = months[mo].kcal.livestock[currentData.type] || {
                milk: 0,
                exchanged: milkPeakExchanged + milkOffPeakExchanged,
                meatEggConsumed: meatEggConsumed
            }
        })

        const calculateMilking = (peakKey: 'milkProductionPeak' | 'milkProductionPeakSecondary', offPeakKey: 'milkProductionOffPeak' | 'milkProductionOffPeakSecondary') => {
            // milk sales
            const incomePeak = Number(currentData[peakKey]?.days_milked) * Number(currentData[peakKey]?.price_per_litre || 0) * Number(currentData[peakKey]?.sold_per_day || 0)
            const peak = getMonthsOnDays(currentData[peakKey]?.peak_milk_month as unknown as Month_Enum, Number(currentData[peakKey]?.days_milked), incomePeak)
            peak?.months.forEach(c => {
                months[c.month].income.livestock[currentData.type].milkSales += c.value
            })
            const incomeOffPeak = Number(currentData[offPeakKey]?.days_milked) * Number(currentData[offPeakKey]?.price_per_litre || 0) * Number(currentData[offPeakKey]?.sold_per_day || 0)
            const offPeak = getMonthsOnDays(peak?.nextMonth, Number(currentData[offPeakKey]?.days_milked), incomeOffPeak)
            offPeak?.months.forEach(c => {
                months[c.month].income.livestock[currentData.type].milkSales += c.value
            })
            // milk kcal
            const kcalPeak = getMonthsOnDays(currentData[peakKey]?.peak_milk_month as unknown as Month_Enum, Number(currentData[peakKey]?.days_milked), currentData.total_peak_milk_calories)
            const kcalOffPeak = getMonthsOnDays(kcalPeak?.nextMonth, Number(currentData[offPeakKey]?.days_milked), currentData.total_off_peak_milk_calories)
            kcalPeak?.months.forEach(c => {
                months[c.month].kcal.livestock[currentData.type].milk += c.value
            })
            kcalOffPeak?.months.forEach(c => {
                months[c.month].kcal.livestock[currentData.type].milk += c.value
            })
        }
        calculateMilking('milkProductionPeak', 'milkProductionOffPeak')
        calculateMilking('milkProductionPeakSecondary', 'milkProductionOffPeakSecondary')


        // sales
        currentData.sales?.forEach(salesData => {
            const fillMonthData = (mo: Month_Enum) => {
                if (checkLivestockSalesCategory(salesData.term?.categoryIds)) {
                    // animal sales
                    months[mo].income.livestock[currentData.type].animalSales += (salesData.total_cash_income / (salesData.months?.length || referenceMonths))
                } else {
                    // animal product sales
                    months[mo].income.livestock[currentData.type].animalProductSales += (salesData.total_cash_income / (salesData.months?.length || referenceMonths))
                }
            }
            if (salesData.months?.length) {
                salesData.months.forEach((mo: Month_Enum) => fillMonthData(mo))

            } else {
                monthsOfPeriod.forEach(m => fillMonthData(m))
            }
        })

        // expenditure
        currentData.purchases?.forEach(purchaseData => {
            const fillMonthData = (mo: Month_Enum) => {
                if (checkLivestockSalesCategory(purchaseData.term?.categoryIds)) {
                    months[mo].expenditure.livestock[currentData.type].animalPurchase += (purchaseData.total_expenses / (purchaseData.months?.length || referenceMonths))
                } else {
                    months[mo].expenditure.livestock[currentData.type].animalInputPurchase += (purchaseData.total_expenses / (purchaseData.months?.length || referenceMonths))
                }
            }
            if (purchaseData.months?.length) {
                purchaseData.months.forEach((mo: Month_Enum) => fillMonthData(mo))

            } else {
                monthsOfPeriod.forEach(m => fillMonthData(m))
            }
        })

        // kcal
        currentData.exchanged_for_food?.forEach(exchangedData => {
            if (exchangedData.is_resold) {
                return // dont calculate anything
            }
            const fillMonthData = (mo: Month_Enum) => {
                months[mo].kcal.livestock[currentData.type].exchanged += (exchangedData.total_kcal / (exchangedData.months?.length || referenceMonths))
            }
            if (exchangedData.months?.length) {
                exchangedData.months.forEach((mo: Month_Enum) => fillMonthData(mo))

            } else {
                monthsOfPeriod.forEach(m => fillMonthData(m))
            }
        })

    })
    return months
}