import {createRoot} from 'react-dom/client'
import "./i18next.ts"
import '@fontsource-variable/inter';
import {StrictMode} from "react";
import App from "./App";
import {LicenseInfo} from '@mui/x-license';

LicenseInfo.setLicenseKey('1c8e6318f9eb27d270be06702193c0e5Tz0xMDIwMjQsRT0xNzYzNjA3MDQ1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=')

createRoot(document.getElementById('app')!).render(
    <StrictMode>
        <App/>
    </StrictMode>
    // <RouterProvider
    // <RouterProvider router={router}/>
)