import {CardDialogAction} from "../../common/popper/CardDialogAction";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {FormContainer} from "react-hook-form-mui";
import {SelfEmploymentForm} from "./partials/SelfEmploymentForm";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {formDialogFlexStyle} from "../../common/form/formFlexStyles";
import {SelfEmploymentDialogSum} from "./partials/SelfEmploymentDialogSum";
import {faStore} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {arrayFieldSum} from "../../lib/helpers/arrayFieldSum";

type SelfEmploymentOverviewProps = {
    interviewId: string
}

const FORM_ID = 'self_employment'

export function SelfEmploymentOverview({interviewId}: SelfEmploymentOverviewProps) {
    const {t} = useTranslation()
    const data = useTssVillageById(interviewId)
    const selfEmploymentData = data?.self_employment
    const totals = data?.total
    const popperRef = useRef<PopupState | null>(null)
    const setVillageData = useSetVillageData()

    return (

        <CardDialogAction
            ref={popperRef}
            category={t('selfEmployment')}
            icon={<FontAwesomeIcon icon={faStore}/>}
            income={totals?.cash_income_self_employment}
        >
            <FormContainer
                FormProps={{
                    id: FORM_ID,
                    style: formDialogFlexStyle
                }}
                defaultValues={{
                    self_employment: selfEmploymentData
                }}
                onSuccess={(formData) => {
                    const updatedData = {
                        ...formData,
                        total: {
                            ...data?.total,
                            cash_income_self_employment: arrayFieldSum(formData.self_employment || [], 'total_cash_income')
                        } as TssVillageData['total']
                    };
                    setVillageData(interviewId, updatedData as TssVillageData)
                    popperRef.current?.close()
                }}>
                <DialogContent>
                    <SelfEmploymentForm name={'self_employment'}/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <SelfEmploymentDialogSum/>
                    <Button form={FORM_ID} type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </FormContainer>
        </CardDialogAction>
    )
}