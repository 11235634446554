import { Block } from "@mui/icons-material";
import {
  Box,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  StepperProps,
} from "@mui/material";

type StepItems = {
  label: string;
  error?: boolean;
  disabled?: boolean;
};

type FormStepperProps = {
  stepperProps?: StepperProps;
  items: StepItems[];
  handleStep: (index: number) => void;
  completed?: {
    [k: number]: boolean;
  };
};
export default function FormStepper({
  items,
  handleStep,
  stepperProps,
  completed = {},
}: FormStepperProps) {
  return (
    <Box
      sx={{
        overflowX: "auto", // Allow horizontal scroll
        overflowY: "hidden", // Prevent vertical scroll
        width: "100%",
        // display: "flex", // Ensure content doesn't stretch vertically
      }}
    >
      <Stepper alternativeLabel {...stepperProps}>
        {items.map((item, index) => (
          <Step
            disabled={item.disabled}
            key={index}
            color={"inherit"}
            completed={completed[index]}
          >
            <StepButton
              color={"inherit"}
              onClick={() => handleStep(index)}
              disabled={item.disabled}
              icon={item.disabled ? <Block /> : false}
            >
              <StepLabel error={item.error} sx={{ whiteSpace: "nowrap" }}>
                {item.label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
