import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Livestock_Type_Enum } from "../../../lib";
import { hasSecondaryMilkOnLivestockType } from "./helpers/calculate";
import MilkingForm from "./partials/MilkingForm";

export default function MilkProductionForm({
  livestockType,
}: {
  livestockType: Livestock_Type_Enum;
}) {
  const [value, setValue] = useState("1");
  const { t } = useTranslation();
  return (
    <div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(_, val) => setValue(val)}
            aria-label="Milk and production form"
            centered
            variant={"fullWidth"}
          >
            <Tab label={t("livestock.peakMilking", { ns: "tss" })} value="1" />
            <Tab
              label={t("livestock.offPeakMilking", { ns: "tss" })}
              value="2"
            />

            <Tab
              sx={{
                display: hasSecondaryMilkOnLivestockType(livestockType)
                  ? undefined
                  : "none",
              }}
              label={t("livestock.peakMilkingSecondary", { ns: "tss" })}
              value="3"
            />
            <Tab
              label={t("livestock.offPeakMilkingSecondary", { ns: "tss" })}
              sx={{
                display: hasSecondaryMilkOnLivestockType(livestockType)
                  ? undefined
                  : "none",
              }}
              value="4"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <MilkingForm
            namePrefix={"milkProductionPeak"}
            livestockType={livestockType}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <MilkingForm
            namePrefix={"milkProductionOffPeak"}
            livestockType={livestockType}
          />
        </TabPanel>
        {hasSecondaryMilkOnLivestockType(livestockType) && (
          <>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <MilkingForm
                namePrefix={"milkProductionPeakSecondary"}
                livestockType={livestockType}
              />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 0 }}>
              <MilkingForm
                namePrefix={"milkProductionOffPeakSecondary"}
                livestockType={livestockType}
              />
            </TabPanel>
          </>
        )}
      </TabContext>
    </div>
  );
}
