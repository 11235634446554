import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormStack } from "../../../common/form/FormStack";
import { TextFieldLabelInput } from "../../../common/form/TextFieldLabelInput";
import TotalAnimalsLabel from "./partials/TotalAnimalsLabel";

type BasicHerdDataFormProps = {
  hideMilk?: boolean;
  hasEggs?: boolean;
};

export default function BasicHerdDataForm({
  hideMilk,
  hasEggs,
}: BasicHerdDataFormProps) {
  const { t } = useTranslation();
  return (
    <Stack direction={{ xs: "column" }} spacing={2}>
      <Stack
        alignItems={"center"}
        gap={2}
        direction={{ xs: "column", sm: "row" }}
      >
        <Typography align={"center"} component={"div"} variant={"body1"}>
          {t("startReferenceYear", { ns: "tss" })}
        </Typography>
        <TotalAnimalsLabel />
      </Stack>
      <FormStack direction={{ xs: "column", sm: "row" }}>
        <TextFieldLabelInput
          flex={1}
          label={t("livestock.breedingFemale", { ns: "tss" })}
          name={"breeding_female"}
        />
        <TextFieldLabelInput
          flex={1}
          label={t("livestock.allOther", { ns: "tss" })}
          name={"animals_other"}
        />
      </FormStack>
      <FormStack direction={{ xs: "column", sm: "row" }}>
        <TextFieldLabelInput
          flex={1}
          label={t("livestock.newBirth", { ns: "tss" })}
          name={"new_birth"}
        />
        <TextFieldLabelInput
          flex={1}
          label={t("livestock.newBirthSecondary", { ns: "tss" })}
          name={"new_birth_secondary"}
        />
        {hasEggs && (
          <TextFieldLabelInput
            label={t("livestock.eggsConsumed", { ns: "tss" })}
            name={"total_eggs_consumed"}
          />
        )}
      </FormStack>
    </Stack>
  );
}
