import {CardDialogAction} from "../../common/popper/CardDialogAction";
import {RemittancesCreditFormTable} from "./RemittancesCreditFormTable";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FormContainer} from "react-hook-form-mui";
import {TssVillageData, useSetVillageData, useTssVillageById} from "../../lib";
import {useRef} from "react";
import {PopupState} from "material-ui-popup-state/hooks";
import {RemittancesDialogSum} from "./RemittancesDialogSum";
import {faMoneyBillTransfer} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {arrayFieldSum} from "../../lib/helpers/arrayFieldSum";

const FORM_ID = 'remittances-credit'

type RemittancesCreditOverviewProps = {
    interviewId: string
}

export function RemittancesCreditOverview({interviewId}: RemittancesCreditOverviewProps) {
    const {t} = useTranslation()
    const popperRef = useRef<PopupState | null>()

    const data = useTssVillageById(interviewId)
    const remittancesCreditData = data?.remittances_credit
    const totals = data?.total
    const setVillageData = useSetVillageData()
    return (
        <FormContainer FormProps={{
            id: FORM_ID
        }}
                       defaultValues={{
                           remittances_credit: remittancesCreditData
                       }}
                       onSuccess={formData => {
                           const updatedData = {
                               remittances_credit: formData.remittances_credit,
                               total: {
                                   ...totals,
                                   cash_expenses_remittance_credit: arrayFieldSum(formData.remittances_credit || [], 'total_expenses'),
                                   cash_income_remittance_credit: arrayFieldSum(formData.remittances_credit || [], 'total_cash_income'),
                               }
                           } as TssVillageData
                           setVillageData(interviewId, updatedData)
                           popperRef.current?.close()
                       }}
        >
            <CardDialogAction income={totals?.cash_income_remittance_credit}
                              expenses={totals?.cash_expenses_remittance_credit}
                              category={t('remittanceCredit')}
                              icon={<FontAwesomeIcon icon={faMoneyBillTransfer}/>}
                              ref={popperRef}>
                <DialogContent>
                    <RemittancesCreditFormTable name={'remittances_credit'}/>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'space-between'}}>
                    <RemittancesDialogSum/>
                    <Button form={FORM_ID} type={'submit'} variant={'contained'}>
                        {t('saveClose')}
                    </Button>
                </DialogActions>
            </CardDialogAction>
        </FormContainer>
    )
}