import {produce} from "immer";
import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {TssNoteFragment, UserFragment} from "../../lib/gql/__codegen__/react-query";
import {useShallow} from "zustand/react/shallow";

export type TssNote = TssNoteFragment & {
    user?: UserFragment
};
type TssNoteState = {
    currentInterviewId: string
    notes: {
        [key: string]: TssNote[]
    },
    notesUpdated: {
        [key: string]: string | null
    }
    showResolved: boolean
    currentMessage: TssNoteFragment | null
    unsetUpdatedAt: (id: string) => void
    setCurrentMessage: (note: TssNoteFragment | null) => void
    setComment: (data: TssNoteFragment) => void
    syncData: (notes: TssNote[]) => void
    setCurrentInterviewId: (id: string) => void
    setShowResolved: (bool: boolean) => void
}

export const useTssNotesStore = create<TssNoteState>()(devtools(persist((set, get) => ({
    notes: {},
    notesUpdated: {},
    showResolved: false,
    currentInterviewId: '',
    currentMessage: null,
    unsetUpdatedAt: (id) => set(produce<TssNoteState>((state) => {
        state.notesUpdated[id] = null
    })),
    setShowResolved: (bool) => set({showResolved: bool}),
    setCurrentInterviewId: (id) => set({currentInterviewId: id}),
    setCurrentMessage: (note) => (set({currentMessage: note})),
    setComment: (data) => set(produce<TssNoteState>((state) => {
        if (!state.notes[data.interview_id]) {
            state.notes[data.interview_id] = [data]
        } else if (state.notes[data.interview_id].find(c => c.id === data.id)) {
            state.notes[data.interview_id] = state.notes[data.interview_id]
                .map(c => c.id === data.id ? data : c)
        } else {
            state.notes[data.interview_id].push(data)
        }
        state.notesUpdated[data.interview_id] = new Date().toISOString()
    })),
    syncData: (notes) => set(produce<TssNoteState>((state) => {
        const stateNotes = get().notes
        const interviewId = get().currentInterviewId
        const mergedArray = mergeArraysByUpdatedAt(stateNotes[interviewId] || [], notes)
        state.notes[interviewId] = mergedArray
        // state.updatedAt[data.project_id] = new Date().toISOString()
    }))
}), {
    name: 'tss-notes',
    version: 1.0
})))


export function useTssNotesCount(interviewId: string) {
    const allNotes = useTssNotesStore(state => state.notes)
    const notes = allNotes[interviewId] || []
    return notes.filter(note => !note.is_deleted && !note.is_done).length
}

export function useTssNotesUpdatedAt() {
    const updatedAt = useTssNotesStore(useShallow(state => state.notesUpdated))
    const interviewId = useTssNotesInterviewId()
    return updatedAt[interviewId]
}

export function useTssNotesList(filter: { showAll?: boolean } = {}) {
    const showResolvedState = useTssNotesStore(useShallow(state => state.showResolved))
    const interviewId = useTssNotesInterviewId()
    const allNotes = useTssNotesStore(state => state.notes)
    const notes = allNotes[interviewId] || []
    if (filter.showAll) {
        return notes
    }
    if (showResolvedState) {
        return notes.filter(note => !note.is_deleted)
    }
    return notes.filter(note => !note.is_deleted && !note.is_done)
}

export const useTssNoteUnsetUpdatedAt = () => {
    const interviewId = useTssNotesInterviewId()
    const unset = useTssNotesStore(useShallow(state => state.unsetUpdatedAt))
    return () => unset(interviewId)
}
export const useTssNotesSyncData = () => useTssNotesStore(useShallow(state => state.syncData))
export const useTssNotesSetCurrentInterviewId = () => useTssNotesStore(useShallow(state => state.setCurrentInterviewId))
export const useTssNotesSetComment = () => useTssNotesStore(useShallow(state => state.setComment))
export const useTssNotesInterviewId = () => useTssNotesStore(useShallow(state => state.currentInterviewId))
export const useTssNotesCurrentMessage = () => useTssNotesStore(useShallow(state => state.currentMessage))
export const useTssNotesSetCurrentMessage = () => useTssNotesStore(useShallow(state => state.setCurrentMessage))


function mergeArraysByUpdatedAt(array1: TssNote[], array2: TssNote[]) {
    const mergedMap = new Map();

    // Populate mergedMap with items from array1
    array1.forEach(item => {
        const id = item.id;
        if (!mergedMap.has(id) || item.updated_at > mergedMap.get(id).updated_at) {
            mergedMap.set(id, item);
        }
    });

    // Populate mergedMap with items from array2
    array2.forEach(item => {
        const id = item.id;
        if (!mergedMap.has(id) || item.updated_at > mergedMap.get(id).updated_at) {
            mergedMap.set(id, item);
        }
    });

    // Convert mergedMap values back to an array
    const mergedArray = Array.from(mergedMap.values());

    return mergedArray;
}

